.upload-container {
  input[type="file"] {
      display: none;
    }

    .custom-file-upload {
      position: fixed;
      top: 20px;
      right: 40px;
      cursor: pointer;
    }

    .upload-csv {
      background-color: #1e7e34;
      width: fit-content;
      padding: 8px 12px;
      border-radius: 16px;
      font-size: 16px;
      font-weight: 500;
      color: #ffffff;
      margin-top: 16px;
    }
}

    .uploaded-achi {
      border: 1px solid green;
      margin-bottom: 4px;
      padding: 8px;
    }

    .error-achi {
      border: 1px solid red;
      margin-bottom: 4px;
      padding:8px;
    }

