.filters-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  z-index: 10;
  top: 0;
  position: sticky;
  border: 0.5px solid gray;
  border-radius: 4px;
  padding: 20px 16px;
  background-color: #ffffff;

  .achi-filter {
    margin-right: 64px;
    display: flex;
    flex-direction: column;
    justify-self: center;
    align-items: center;
  }

  .filter-label {
    font-size: 12px;
    color: gray;
  }

  .game-select {
    min-width: 300px;
    margin-top: 4px;
    height: 40px;
  }
}
