.main-loader {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-container {
  margin: 80px 60px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .top-row {
    display: flex;
    justify-content: space-between;
    // width: 50%;
    // margin-bottom: 50px;
    width: 100%;
    margin-top: 62px;
  }

  .subtitle {
    font-size: 24px;
    color: #000;
    font-weight: bold;
    margin-bottom: 32px;
  }

  .genre-overview {
    width: 45%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }


  .achievement-yearly {
    width: 45%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

}
