.record-container {
  position: relative;

  .genre-filter {
    position: absolute;
    top: -40px;
    right: 8px;
    z-index: 1;
    max-width: 350px;
    min-width: 200px;
  }
}

.clip-loader-bubble {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 200px;
  width: 100%;
  // height: 600px;
}
