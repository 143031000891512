.user-summary-container{
  width: fit-content;
  display: flex;
  justify-content: flex-start;
  // height: 300px;
  margin-bottom: 80px;

  .left-section {
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;


  .avatar-container {
      display: flex;
      justify-content: center;
    }

    .logo {
      width: 108px;

      border-radius: 50%;
    }

    .user-name {
      // margin-left: 20px;
      margin-top: 20px;

      >span {
        font-size: 20px;
        font-weight: 600;

      }
    }

  }
  .right-section {
    width: fit-content;
    margin-left: 80px;

    .user-overview {
        width: fit-content;
        margin-left: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: 1px solid #e6e6e6;
        padding: 16px 24px;
        border-radius: 6px;

        .title {
          font-size: 20px;
          font-weight: 600;
          margin-bottom: 24px;
        }
      }

      .details {
        width: fit-content;
        display: flex;
        justify-content: space-between;
      }

      .card-container {
        display: flex;
        flex-direction: column;

      }

      .card {
        margin-bottom: 16px;
        width: 200px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .card-title {
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 8px;
        }

        .card-value {
          font-size: 24px;
          font-weight: bold;
          margin-bottom: 8px;
          color: #139b13;
        }
      }

    .flex-container {
      width: 500px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;

      .subtitle {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 8px;
      }

      .flexes {
        width: 70%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin-top: 20px;

      }
    }
  }
}
